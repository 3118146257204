import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'aim-icon',
  templateUrl: './aim-icon.component.html',
  styleUrls: ['./aim-icon.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class AimIconComponent {
  @Input() public icon: string;

  public get iconClass(): string {
    return `icon-aim-icon-${this.icon}`;
  }
}
